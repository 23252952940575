.header__links {
  --gap: 24px;

  display: flex;
  align-items: center;
  margin-right: 40px;
}
@media (--tablet) {
  .header__links {
    --gap: 16px;

    margin-right: 16px;
  }
}
@media (--header-breakpoint) {
  .header__links {
    display: none;
  }
}

.header__link {
  position: relative;
}

.header__link > a {
  --padding-left: calc(var(--gap) / 2);
  --padding-right: calc(var(--gap) / 2);

  position: relative;

  display: flex;
  align-items: center;
  height: 100%;
  height: var(--header-height);
  padding-right: var(--padding-right);
  padding-left: var(--padding-left);

  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.12;

  color: var(--color);
  text-decoration: none;

  transition: text-decoration-color 200ms;
  pointer-events: auto;
}
.header__link:last-child > a {
  padding-right: 0;
}

.header__link > a::after {
  --scale: 0.2;

  position: absolute;
  top: 50%;
  right: var(--padding-right);
  left: var(--padding-left);

  content: '';
  display: block;
  height: 2px;

  background-image: linear-gradient(
    to right,
    rgba(var(--primary-500-rgb), 0) 0%,
    var(--primary-500) 50%,
    rgba(var(--primary-500-rgb), 0) 100%
  );
  transform: translateY(16px) scaleX(var(--scale));
  opacity: 0;
  transition:
    200ms opacity,
    200ms transform;
}
.header__link:last-child > a::after {
  right: 0;
}
.header__link--current > a::after,
.header__link:hover > a::after {
  --scale: 1;

  opacity: 1;
}
.header__link:last-child:hover > a::after {
  --scale: 1;
}

.header__link__sub-icon {
  --size: 10px;

  display: block;
  width: var(--size);
  height: var(--size);
  margin-left: 8px;
}

/* Hitbox */
.header__link--has-sub-links:hover::before {
  --size: 300px;

  position: absolute;
  top: 50%;
  left: 50%;

  content: '';
  display: block;
  width: var(--size);
  height: var(--size);

  transform-origin: top left;
  transform: translateY(-24px) scaleY(0.3) rotate(45deg);
}

.header__link__sub-links {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 240px;

  border-radius: 8px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  opacity: 0;
  pointer-events: none;
  transform-origin: top center;
  transform: translateX(-50%) translateY(0);
  transition:
    200ms opacity,
    200ms transform;
}
.header__link:hover .header__link__sub-links {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%) translateY(-8px);
}

.header__link__sub-link {
  --horizontal-padding: 20px;

  display: block;
  width: 100%;
  padding: 14px var(--horizontal-padding) 16px var(--horizontal-padding);

  line-height: 1.3;

  color: var(--black);
  text-decoration: none;
  cursor: pointer;
}
.header__link__sub-link:hover {
  color: var(--primary-500);

  background-color: rgba(var(--primary-500-rgb), 0.1);
}
* + .header__link__sub-link {
  border-top: 1px solid rgba(var(--black-rgb), 0.1);
}
