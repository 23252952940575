.header {
  --color: var(--white);
  --logo-height: 32px;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-header);

  flex-direction: column;

  justify-content: center;
  height: var(--header-height);

  pointer-events: none;
  transition: background-color 0.3s ease;
}
.header--scrolled,
.header--other {
  background-color: var(--theme-black);
}
.header--override-header:not(.header--scrolled) {
  background-color: transparent;
}
@media (--mobile) {
  .header {
    --logo-height: 32px;
  }
}

.header .max-width {
  position: relative;

  display: flex;

  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.header__logo {
  position: absolute;
  left: var(--page-left-padding);

  display: flex;
  align-items: center;
  height: var(--logo-height);

  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
}

.header__logo img {
  display: block;
  align-items: center;
  width: auto;
  height: 100%;
  user-select: none;
}
