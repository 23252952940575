.header__cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  pointer-events: auto;
}
@media (--tablet) {
  .header__cta {
    --gap: 8px;
  }
  .header__cta .button {
    padding-right: 12px;
    padding-left: 12px;

    font-size: 15px;
  }
}
@media (--header-breakpoint) {
  .header__cta {
    display: none;
  }
}
